import Swal from "sweetalert2";
import "jquery-lazy";
import languageBundle from "@kirschbaum-development/laravel-translations-loader/php!@kirschbaum-development/laravel-translations-loader";
import i18next from "i18next";
window.Swal = Swal;
window.languageBundle = languageBundle;
window.i18next = i18next;

$(function () {
    // Add selected attr to select list
    $("#homeSortBy").change(function () {
        var value = $(this).val();
        $(this)
            .find('option[value="' + value + '"]')
            .attr("selected", "selected");
    });

    // Change background color of "add to wishlist" button
    $(".add-to-wish > span > i").mouseover(function () {
        $(this).removeClass("lni-heart");
        $(this).addClass("lni-heart-fill");
    });

    $(".add-to-wish > span > i").mouseleave(function () {
        $(this).removeClass("lni-heart-fill");
        $(this).addClass("lni-heart");
    });

    // Add comma to brands link
    $(".brand-link").not(":last-child").after("<span>, </span>");
    $(".tags .tag-link").not(":last-child").after("<span>, </span>");

    // Reset all form inputs & clear errors messages
    let formInput = $('input[type="text"], input[type="search"], textarea');
    $(formInput).on("keyup", function () {
        $(this).removeClass("is-invalid");
        $(this).removeClass("search-error");
        $(this).next(".alert-error").text("");
    });

    let formSelect = $("select");
    $(formSelect).on("change", function () {
        $(this).removeClass("is-invalid");
    });

    // Scroll to top
    $(window).scroll(function () {
        let backToTo = $(".scroll-top");
        if ($(window).scrollTop() > 50) {
            $(backToTo).css("display", "flex");
        } else {
            $(backToTo).css("display", "none");
        }
    });

    $("#scrollTop").click(function () {
        $("html, body").animate({ scrollTop: 0 }, 300);
    });

    // Change border color after search validation
    $(".search-input > input").on("keyup", function () {
        $(this).removeClass("search-error");
    });

    // jQuery Lazy
    $("img.lazy").Lazy({
        effect: "fadeIn",
        effectTime: 500,
    });

    // Initialize tooltips
    let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });
});
